@import '../../../../assets/styles/Colors.scss';

.contact-section {
  height: calc(100vh - 200px);
  display: flex;
  margin-top: 100px;

  .contact {
    width: 50%;
    margin-top: 100px;

    .title {
      margin-bottom: 20px;
    }

    .description {
      width: 55%;
      margin-top: 100px;
    }

    .follow-me {
      width: 80%;
      display: flex;
      align-items: center;
      font-weight: 900;
      color: $gray-black;
    }

    .icons {
      display: flex;

      flex-wrap: wrap;
      margin-left: 30px;
      .icon {
        width: 30px;
        margin: 20px;
        cursor: pointer;
      }
    }
  }

  .contact-image {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 100px;

    .image {
      width: 80%;
    }
  }
}

.email-contact {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.email-icon {
  width: 50px;
  height: 50px;
  // border: 1px solid $gray-white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 60%;
    height: auto;
  }
}

.email-info {
  color: $gray-white;
  font-size: 16px;
}

@media only screen and (max-width: 1500px) {
  .contact-section .contact {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
  .contact-section .contact-image {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .contact-section {
    margin-top: 0px;
    .contact {
      margin-top: 50px;

      .description {
        margin-top: 50px;
      }
    }
  }

  .contact-section .contact .follow-me {
    flex-direction: column;
    align-items: flex-start;

    .icons {
      margin-left: 0px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .contact-section .contact .description {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .contact-section .contact {
    width: 85%;
    .follow-me {
      .icon {
        margin: 25px;
      }
    }
  }
}

.margin-left-0 {
  margin-left: 0px !important;
}
