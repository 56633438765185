@mixin define-margins-top($size) {
  .margin-top-#{$size} {
    margin-top: $size + px;
  }
}

@mixin define-margins-left($size) {
  .margin-left-#{$size} {
    margin-left: $size + px;
  }
}

@mixin define-margins-bottom($size) {
  .margin-bottom-#{$size} {
    margin-bottom: $size + px;
  }
}

@mixin define-margins-right($size) {
  .margin-right-#{$size} {
    margin-right: $size + px;
  }
}

@include define-margins-top(10);
@include define-margins-top(20);
@include define-margins-top(30);
@include define-margins-top(40);
@include define-margins-top(50);
@include define-margins-top(60);
@include define-margins-top(70);
@include define-margins-top(80);
@include define-margins-top(90);
@include define-margins-top(100);

@include define-margins-left(5);
@include define-margins-left(10);
@include define-margins-left(20);
@include define-margins-left(30);
@include define-margins-left(40);
@include define-margins-left(50);

@include define-margins-bottom(5);
@include define-margins-bottom(10);
@include define-margins-bottom(20);
@include define-margins-bottom(30);
@include define-margins-bottom(40);
@include define-margins-bottom(50);
@include define-margins-bottom(60);
@include define-margins-bottom(70);
@include define-margins-bottom(80);

@include define-margins-right(5);
@include define-margins-right(10);
@include define-margins-right(20);
@include define-margins-right(30);
@include define-margins-right(40);
@include define-margins-right(50);
