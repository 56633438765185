@import '../../../../assets/styles/Colors.scss';

.projects-section {
  flex-direction: column;
  margin-top: 100px;

  .projects-content {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-top: 100px;

    .title {
      width: 50%;
    }
    .description {
      width: 80%;
    }
  }

  .carousel {
    display: flex;
    width: calc(100% + 100px);
    position: relative;
    left: -50px;
    margin-top: 100px;
    z-index: 1;

    .cards {
      display: flex;
      width: 100%;
      // overflow-x: scroll;
      // scrollbar-width: thin;
      // scroll-behavior: smooth;

      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }

      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: $gray-white;
      }

      &::-webkit-scrollbar-track {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background-color: $gray-background;
      }
    }

    .back-button,
    .next-button {
      height: 30px;
      width: auto;
      margin: auto;
      cursor: pointer;
    }

    .back-button {
      margin-right: 20px;
      visibility: hidden;
    }

    .next-button {
      margin-left: 20px;
      visibility: hidden;
    }
  }
}

.width-50 {
  width: 50%;
}

.project-illustration-section {
  display: flex;
  justify-content: flex-end;
  margin-right: 120px;
}
.project-illustration {
  width: 70%;
}

@media only screen and (max-width: 1500px) {
  .projects-section .first-projects .project-extended:first-child {
    margin-right: 10px;
  }

  .projects-section .projects-content {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
  .projects-section .first-projects {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .project-extended {
      margin: 0px;
      width: 48%;

      .info {
        width: 100%;
        margin-right: 0px;
      }
    }
  }
  .projects-section .carousel {
    margin-left: auto;
    margin-right: auto;
    width: calc(70% + 100px);
    left: 0px;
  }
  .projects-section .carousel .cards {
    overflow-x: scroll;
    scrollbar-width: thin;
    scroll-behavior: smooth;
  }
  .projects-section .carousel .back-button,
  .projects-section .carousel .next-button {
    visibility: visible;
  }

  .projects-section .projects-content .description {
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .projects-section {
    margin-top: 0px;

    .projects-content {
      margin-top: 50px;

      .title {
        width: 100%;
      }
    }

    .carousel {
      margin-top: 50px;
    }
  }

  .projects-section .first-projects {
    flex-direction: column;

    .project-extended {
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto !important;
      width: 100%;
      min-height: 400px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .projects-section .projects-content {
    width: 85%;
  }

  .projects-section .carousel {
    width: 98%;
    .back-button {
      margin-right: 0px;
    }

    .next-button {
      margin-left: 0px;
    }
  }

  .projects-section .first-projects {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .project-extended {
      width: 94% !important;
    }
  }
}
