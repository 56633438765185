@import '../../../assets/styles/Colors.scss';

.header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  height: 100px;
  align-items: center;
  padding: 0 50px;
  width: calc(99vw - 50px);
  background-color: white;
  top: 0px;
  z-index: 1000;
  //-webkit-transition-duration: 0.1;
  //transition-duration: 0.1s;
  -webkit-transition-property: all;
  transition-property: all;
  //-webkit-transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  // transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  border-bottom: 1px solid #e9e9e9;
}

.header.down {
  -webkit-box-shadow: 0 0 0.9375rem 0 rgba(47, 70, 73, 0.2);
  box-shadow: 0 0 0.9375rem 0 rgba(47, 70, 73, 0.2);
  border-color: #fff;
  background: #fff;
}

.logo {
  font-size: 20px;
  cursor: pointer;

  .text-purple {
    color: $blue;
  }

  font-weight: 900;
  color: $gray-black;

  .logo-icon {
    width: 86px;
  }
}

.me {
  display: flex;
  align-items: center;

  .image-section {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }

  .name-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;

    .name {
      font-weight: 900;
      color: $gray-black;
      font-size: 20px;
      margin-bottom: 3px;
    }

    .position {
      color: $gray-white;
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .header {
    height: 70px;

    padding: 0 30px;
    width: calc(99vw - 30px);
  }

  .logo {
    //margin-top: 5px;

    .logo-icon {
      width: 50px;
    }
  }
}

  

//   .me {
//     .image-section {
//       width: 50px;
//       height: 50px;
//     }

//     .name-section {
//       .name {
//         font-size: 18px;
//       }

//       .position {
//         font-size: 14px;
//       }
//     }
//   }
// }
