@import './Colors.scss';
@import './Paddings.scss';
@import './Margins.scss';
@import 'ResetDefaultCSS.scss';

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v17-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('../fonts/open-sans-v17-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans-v17-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('../fonts/open-sans-v17-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans-v17-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/open-sans-v17-latin-regular.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

* {
  font-family: 'Open Sans', sans-serif;
}
