@import 'Colors.scss';

.button {
  padding: 15px 40px;
  background-color: $blue;
  width: fit-content;
  color: white;
  border-radius: 6px;
  cursor: pointer;
}

.button-large {
  padding: 20px 40px;
}

.button-text-center {
  display: flex;
  justify-content: center;
}
