.display-flex {
  display: flex;
}

.direction-column {
  flex-direction: column;
}

.content-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.w-100 {
  width: 100%;
}
