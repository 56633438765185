@import '../../../../assets/styles/Colors.scss';

.timeline-item {
  display: flex;

  .timeline {
    display: flex;
    flex-direction: column;
    align-items: center;

    .dot {
      height: 24px;
      width: 24px;
      background-color: $blue;
      border-radius: 50%;
      display: inline-block;
    }

    .small-dot {
      height: 14px;
      width: 14px;
      background-color: white;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      left: -19px;
    }

    .line {
      height: 100%;
      width: 3px;
      background-color: $blue;
      position: relative;
      left: -7px;
      top: 0px;
    }
  }

  .text {
    width: 60%;

    .title {
      font-size: 20px;
      font-weight: 900;
      margin-bottom: 10px;
      color: $gray-black;
      line-height: 27px;
    }

    .location {
      margin: 10px 0px;
      display: flex;
      align-items: center;
      color: $gray-white;
      .icon {
        width: 25px;
        margin-right: 5px;
      }
    }

    .sub-title {
      color: $gray-white;
      line-height: 22px;
    }
  }
}

.blue {
  .dot {
    background-color: $blue !important;
  }

  .line {
    background-color: $blue !important;
  }
}

.orange {
  .dot {
    background-color: $orange !important;
  }

  .line {
    background-color: $orange !important;
  }
}

.visible-only-mobile {
  display: none;
}

@media only screen and (max-width: 1500px) {
  .timeline-item .text {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .timeline-item {
    flex-direction: column;
    margin-bottom: 20px;

    .timeline {
      display: none;
    }

    .years {
      margin-bottom: 10px;
    }
  }
}
