@mixin define-paddings-top($size) {
  .padding-top-#{$size} {
    padding-top: $size + px;
  }
}

@mixin define-paddings-left($size) {
  .padding-left-#{$size} {
    padding-left: $size + px;
  }
}

@mixin define-paddings-bottom($size) {
  .padding-bottom-#{$size} {
    padding-bottom: $size + px;
  }
}

@include define-paddings-top(10);
@include define-paddings-top(20);
@include define-paddings-top(30);
@include define-paddings-top(40);
@include define-paddings-top(50);
@include define-paddings-top(60);
@include define-paddings-top(70);
@include define-paddings-top(100);

@include define-paddings-left(5);
@include define-paddings-left(10);
@include define-paddings-left(20);
@include define-paddings-left(30);
@include define-paddings-left(40);
@include define-paddings-left(50);

@include define-paddings-bottom(5);
@include define-paddings-bottom(10);
@include define-paddings-bottom(20);
@include define-paddings-bottom(30);
@include define-paddings-bottom(40);
@include define-paddings-bottom(50);
