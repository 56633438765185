@import '../../../../assets/styles/Colors.scss';

.education-section {
  min-height: calc(100vh - 300px);

  .details {
    background-color: $orange-lite-background;
  }
}

@media only screen and (max-width: 1500px) {
  .education-section .history,
  .education-section .details {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
}

@media only screen and (max-width: 1000px) {
  .education-section {
    margin-top: 0px;

    .history {
      margin-top: 50px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .education-section .history,
  .education-section .details {
    width: 85%;
    padding: 18px;
    margin-top: 0px;
  }
}
