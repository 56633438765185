@import '../../assets/styles/Colors.scss';

.section {
  width: 100%;
  max-width: 1296px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  margin-bottom: 50px;

  .title {
    margin-bottom: 100px;
    font-size: 30px;
    color: $gray-black;
    font-weight: 900;
  }

  .description {
    font-size: 20px;
    line-height: 30px;
    color: $gray-white;
  }
}

.container {
  width: 100%;
  max-width: 1296px;

  padding-right: 24px;
  padding-left: 24px;
  -webkit-perspective: 500px;
  perspective: 500px;
}

@media only screen and (max-width: 1500px) {
  .section {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1000px) {
  .section .title {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 800px) {
  .content {
    margin-left: 0px;
  }
}
