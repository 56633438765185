@import '../../../assets/styles/Colors.scss';

.list {
  list-style-type: none;
  display: flex;

  * {
    margin: 20px;
    padding: 5px 5px;
    color: $gray-black;
    font-weight: 900;
    cursor: pointer;
  }

  .selected {
    border-bottom: 3px solid $orange;
  }
}

.item-disabled {
  color: #aaaaaa;
}
