$purple: #5843be;
$blue: #5358e3;
$gray-black: #292929;
$gray-white: #332f2f;
$orange: #ffa41b;
$gray-background: #eeeeee;
$orange-lite-background: #ffe4be;
$green: #3ccf91;

$icon-gray: #575757;
$icon-color: #5843be;

.white-background {
  background-color: white !important;
}

.text-black {
  color: $gray-black !important;
}

.text-gray {
  color: $gray-white !important;
}

.green-background {
  background-color: #3ccf91 !important;
}

.red-background {
  background-color: #f74a71 !important;
}
.white-text {
  color: white !important;
}

.orange-background {
  background-color: $orange;
}
