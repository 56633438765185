@import '../../../assets/styles/Colors.scss';

.sidebar {
  position: fixed;
  top: 0;
  right: -250px;
  width: 250px;
  height: 100%;
  background-color: $gray-background;
  transform: translate3d(0, 0, 0);
  transition: all 100ms ease-in-out;
}
.sidebar.active {
  right: 0;
  // transform: translate3d(100%, 0, 0);
}

.button-section {
  z-index: 1000;
  height: 100px;
  display: flex;
  justify-content: flex-end;
  // border-bottom: 1px solid white;
  align-items: center;

  .menu-icon {
    width: 40px;
    height: 40px;
    margin-right: 20px;
    z-index: 1000;
  }
}

.menu-items {
  margin-top: 150px;

  .item {
    margin: 30px 30px;
    padding-bottom: 5px;
    padding-right: 2px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 900;
    width: fit-content;
    font-size: 20px;
  }
}

.sidebar li {
  list-style: none;
}

.disabled {
  color: #aaaaaa;
}

.menu-items .selected {
  border-bottom: 3px solid $orange;
}

.close-icon {
  width: 30px !important;
  height: 30px !important;
}
