@import '../../../../assets/styles/Colors.scss';

.project-extended {
  display: flex;
  width: 100%;
  margin: 30px 0px;

  .info {
    width: 300px;
    background-color: $gray-background;
    margin-right: 30px;
    border-radius: 25px;
    padding: 50px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .logo {
      width: 30%;
    }

    .project-title {
      color: $gray-black;

      font-size: 25px;
      line-height: 35px;
      font-weight: 600;
      letter-spacing: -0.02em;
    }

    .project-description {
      margin-top: 15px;
      color: $gray-white;

      font-size: 20px;
      line-height: 25px;
    }

    .view-project {
      width: calc(100% - 80px);
      text-decoration: none;
    }
  }

  .project-image {
    width: 70%;
    border-radius: 25px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .image {
      width: 100%;
      border-radius: 25px;
    }
  }
}

@media only screen and (max-width: 1500px) {
  .project-extended .project-image {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .project-extended {
    width: 94%;
    .info {
      padding: 18px;
    }
  }
}
