@import '../../../../assets/styles/Colors.scss';

.skills-section {
  margin-top: 100px;
}
.skills {
  height: 100%;
  margin-top: 100px;
  width: 80%;

  .category {
    font-size: 20px;
    font-weight: 900;
    display: flex;
    align-items: center;

    color: $gray-black;

    .icon {
      width: 30px;
      margin-right: 10px;
    }
  }

  .skills-list {
    font-size: 20px;
    line-height: 30px;
    color: white;
    margin-left: -20px;

    display: flex;
    flex-wrap: wrap;

    .skill {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: $gray-background;
      margin: 10px;
      border-radius: 25px;

      .skill-logo {
        width: 50px;
        height: auto;
        padding: 15px 15px 15px 20px;
      }

      .skill-text {
        font-size: 18px;
        padding: 10px 20px 10px 0px;
        font-weight: 600;
      }
    }

    * {
      color: $gray-white;
    }
  }
}

.skills-image {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 100px;

  .image {
    width: 70%;
  }
}

@media only screen and (max-width: 1500px) {
  .skills-section .skills {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .skills-section .skills-image {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .skills-section {
    margin-top: 0px;

    .skills {
      margin-top: 50px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .skills-section .skills {
    width: 85%;
  }
}
