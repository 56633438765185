@import '../../../../assets/styles/Colors.scss';

.experience-section {
  display: flex;
  min-height: calc(100vh - 100px);
  margin-top: 100px;
}

.history {
  height: 100%;
  margin-top: 100px;
  width: 60%;

  .years {
    color: $gray-black;
    min-width: 150px;
    font-weight: 900;
    margin-top: 5px;
  }
}

.details {
  height: 100%;
  width: 40%;
  background-color: $blue;
  color: white;
  padding: 80px;
  border-radius: 25px;

  .title {
    width: 100%;
    font-size: 30px;
    color: white;
  }

  .description {
    width: 100%;
    font-size: 20px;
    line-height: 30px;
    color: white;
  }
}

@media only screen and (max-width: 1500px) {
  .experience-section {
    flex-direction: column;
  }

  .experience-section .history,
  .experience-section .details {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
}

@media only screen and (max-width: 1000px) {
  .experience-section {
    margin-top: 0px;

    .history,
    .details {
      margin-top: 50px;

      .title {
        margin-bottom: 50px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .experience-section .history {
    width: 85%;
  }
  .experience-section .details {
    width: 85%;
    padding: 18px;
    margin-top: 0px;
  }
}
