@import '../../../../assets/styles/Colors.scss';

.presentation-section {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 100px);
  justify-content: space-between;

  .presentation {
    width: 55%;
    margin-top: 100px;
    display: flex;
    flex-direction: column;

    .title {
      margin-top: 70px;
      margin-bottom: 0px;
      font-size: 40px;
      line-height: 50px;
      width: 50%;
      font-weight: 900;
      color: $gray-black;
    }

    .sub-title {
      margin-top: 50px;

      font-size: 20px;
      line-height: 30px;
      width: 80%;
      color: $gray-white;
    }
  }

  .image-section {
    width: 45%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .image {
      margin-top: 100px;
      width: 80%;
      height: auto;
    }
  }

  .read-more {
    display: flex;
    justify-content: center;
    color: $gray-white;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 50px;

    &:hover {
      color: $gray-black;
    }
    .text {
      padding-bottom: 5px;
    }

    .image {
      width: 30px;
    }
  }
}

.me {
  display: flex;
  align-items: center;

  .user-section {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: flex-start;

    img {
      width: 100%;
      height: auto;
      margin-top: -3px;
    }
  }

  .name-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;

    .name {
      font-weight: 900;
      color: $gray-black;
      font-size: 20px;
      margin-bottom: 10px !important;
    }

    .position {
      color: $gray-white;
      font-size: 16px;
    }
  }
}

.quote-presentation {
  width: calc(60%) !important;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
}
.quote-section {
  display: flex;
  width: 40%;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .quote {
    width: 90%;
    font-size: 25px;
    line-height: 35px;
    color: $gray-black;
    text-align: center;
    font-style: italic;
  }

  .quote-author {
    font-size: 20px;
    color: $blue;
  }
}

@media only screen and (max-width: 1500px) {
  .presentation-section .image-section {
    display: none;
  }

  .presentation-section .presentation {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
}

@media only screen and (max-width: 1000px) {
  .presentation-section .presentation {
    margin-top: 50px;
  }
  .presentation-section .presentation .title {
    width: 100%;
  }

  .presentation-section .presentation .sub-title {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .presentation-section .presentation {
    width: 85%;
  }

  .presentation-section .read-more {
    display: none;
  }
}
