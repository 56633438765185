@import '../../../../assets/styles/Colors.scss';

.card-section {
  background-color: $gray-background;
  width: 200px;
  min-width: 200px;
  height: 250px;
  border-radius: 15px;
  cursor: pointer;
  margin-left: 15px;
  margin-right: 15px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:first-child {
    margin-left: 0px;
  }

  .card-title {
    color: $gray-black;
    font-size: 20px;
  }

  .card-description {
    width: 100%;
    color: $gray-white;
    font-size: 16px;
    line-height: 25px;
  }

  .more {
    font-weight: 900;
    color: $gray-black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    .next-button {
      height: 15px;
      margin-left: 8px;
      visibility: visible;
    }
  }
}
