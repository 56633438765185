@import '../../../../assets/styles/Colors.scss';

.home-agenda-section {
  position: fixed;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  z-index: 100;

  .home-agenda {
    width: 185px;
    height: 65%;
    margin-top: auto;
    margin-bottom: auto;
    background-color: $gray-background;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
  }

  .agenda {
    width: 100%;

    list-style-type: none;
    color: $gray-black;

    display: flex;
    flex-direction: column;
    justify-content: center;

    .item {
      margin: 15px 0px;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .item-icon {
        margin-right: 10px;
        width: 40px;
        height: auto;
      }
    }

    .selected {
      color: $blue;
    }
  }

  .expand {
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
    margin-bottom: 20px;

    .expand-icon {
      cursor: pointer;
      width: 20px;
    }
  }

  .close {
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
    margin-top: 20px;
    min-height: 20px;

    .close-icon {
      cursor: pointer;
      width: 20px !important;
      height: 20px !important;
    }
  }

  .agenda-collapsed {
    width: 80px;
    transform: translate3d(0, 0, 0);
    transition: all 100ms ease-in-out;
    min-height: 500px;
  }

  .agenda-extended {
    transform: translate3d(0, 0, 0);
    transition: all 100ms ease-in-out;
    min-height: 510px;
  }

  .agenda-toggle-section {
    margin-left: 10px;
    background-color: $gray-background;
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    transform: translate3d(0, 0, 0);
    transition: all 100ms ease-in-out;

    .agenda-icon {
      width: 40px;
      height: auto;
    }
  }
}

.close-agenda-icon {
  width: 30px;
}

@media only screen and (max-width: 800px) {
  .home-agenda-section {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .agenda-toggle-section {
    display: none !important;
  }
}

@media only screen and (min-width: 1100px) {
  .agenda-toggle-section {
    display: none !important;
  }
}
